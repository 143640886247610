import React from 'react'
import {Grid} from '@material-ui/core'

const Client = () => {

    const items = [
                        'https://ik.imagekit.io/torit/Component_3_____1_3x_tNTYZmcgH.png',
                        'https://ik.imagekit.io/torit/Mask_Group_3_3x_jQAvDm8Vp.png',
                        'https://ik.imagekit.io/torit/Mask_Group_4_3x_GOIe6_HaoW.png',
                        'https://ik.imagekit.io/torit/Mask_Group_5_3x_2B6MPrEjbs.png',
                        'https://ik.imagekit.io/torit/Mask_Group_6_3x_CjJzASKBiG.png',
                        'https://ik.imagekit.io/torit/Component_2_____1_3x_3C91ds-FK.png'
                    ]

    const image = []
    
    for (const [index, item] of items.entries()) {
        image.push (<Grid key={index} item xs={6} md={2}><img className="responsive-image" alt='client' src={item}/></Grid>)
    }

    return (
        <div className='px2'>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={11} md={10}>
                    <h2 style={{textAlign: 'center', marginBottom: '40px'}}>
                        Trusted by high growth companies
                    </h2>
                    <Grid container justify='center'>
                        {image}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Client;
