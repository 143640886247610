import React from 'react'
import { Grid, TextField, TextareaAutosize, Button } from '@material-ui/core'

const Contact = () => {
    return (
        <div className='px2' style={{backgroundColor: '#0097ea', color:'white'}}>
            <Grid container justify='center'>
                <Grid item xs={11} md={10}>
                    <Grid container justify='center'>
                        <Grid item xs={11} md={6}>
                            <h3 style={{color:'#ffffff'}}>Submit your enquiry and we will contact you</h3>
                            <Grid container justify='center'>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Name"
                                        type="text"
                                        className="textfield"
                                        margin="normal"
                                        variant="outlined"
                                        style={{backgroundColor:'white'}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Mobile"
                                        type="number"
                                        className="textfield"
                                        margin="normal"
                                        variant="outlined"
                                        style={{backgroundColor:'white'}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextareaAutosize
                                        id="outlined-required"
                                        label="Message"
                                        type="text"
                                        className="textfield"
                                        margin="normal"
                                        variant="outlined"
                                        aria-label="minimum height"
                                        rows={5}
                                        placeholder="Message"
                                        style={{marginTop: 20, height: 100, fontSize: '1.1em'}}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                <Button variant="contained" size="large" color="primary" style={{width: '100%', marginTop: '20px'}}>
                                    Submit
                                </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={11} md={4}>
                            <h2 style={{color: 'white'}}>Feel free to contact</h2>
                            <h3 style={{color: 'white'}}>Torit Inc., USA</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>3422 Belridge Drive</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>SE.Smyrna, GA 30080</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>+14439179870</p>
                            <h3 style={{color: 'white'}}>Corporate Address:</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>68 14th Cross 1st Block</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>RT Nagar, Bangalore 560032</p>
                            {/* <h3 style={{color: 'white'}}>Registered Address:</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>C/27 East Rajapur</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>Santoshpur, Kolkata 700075</p> */}
                            <h3 style={{color: 'white'}}>Contact:</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>+918794054793</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>info@torit.in</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Contact
