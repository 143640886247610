import React from 'react'
import { Grid, Button, Modal } from '@material-ui/core'
import ModalForm from '../../../Shared/ModalForm'

const Hero = () => {

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div id='Home' className="divison paper">
            <Grid container justify='center'>
                <Grid item xs={11}>
                    <Grid container justify='space-evenly' alignItems='center' alignContent='center'>
                        <Grid item xs={12} md={5}>
                            <Grid container justify='flex-start' alignItems='center'>
                                <h1 style={{fontSize: '40px', color: 'white', lineHeight: '100%'}}>Transform Everything</h1>
                                <h1 style={{fontSize: '40px', color: '#f7cb0f', lineHeight: '100%', marginTop: -15}}>From Business to life</h1>
                                <h4 style={{color: 'white', marginBottom: '50px'}}>Everything is being wired up or connected wirelessly</h4>
                                <Grid container justify='flex-start' spacing={1}>
                                    <Grid item xs={6} md={5}>
                                        <Button variant="contained" onClick={handleOpen} style={{backgroundColor: 'white', color: '#0099e7', width:'100%', height: 60, marginRight:5, fontSize:'1.1em', fontWeight: 'bold'}}>Get Started</Button>
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <Button  style={{color: 'white', width: '100%', height: 60, marginLeft: 5, textDecoration: 'initial', fontSize:'1.1em', fontWeight: 'bold'}}>Or learn how we do it</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <img className='responsive-img mt30' src="https://ik.imagekit.io/torit/Group_1264_q9o8F_j3d.svg" alt='hero'></img>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="Modal-form-title"
                aria-describedby="Modal-form-description"
                open={open}
                onClose={handleClose}
                style={{overflowY:'scroll'}}
            >
                <div className='modal-form'>
                    <ModalForm />
                </div>
            </Modal>
        </div>
    )
}

export default Hero
