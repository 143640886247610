import React, {useState} from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import MuiPhoneNumber from 'material-ui-phone-number'

const Bottom = () => {

    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [err, setErr] = useState({emailText: '', nameText:'', numberText:''})

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const handleNameChange = event => {
        setName(event.target.value)
    }

    const handleNumberChange = event => {
        setNumber(event)
    }

    const handleMessageChange = event => {
        setMessage(event.target.value)
    }

    const validateEmail = () => {

        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email) || email.length===0) {
            setErr({ ...err,
                emailText: ''
            })
        } else {
            setErr({ ...err,
                emailText: 'Please enter a valid email'
            })
        }
    }

    const validateName = () => {
        let re = /^[A-Za-z\s]+$/
        if(re.test(name)) {
            setErr ({ ...err,
                nameText: ''
            })
        } else {
            setErr ({ ...err,
                nameText: 'Please enter a valid name'
            })
        }
    }

    const onSubmitHandle = () => {

        if (name.length===0) {
            setErr ({ ...err,
                nameText:'Please enter name'
            })
        } else {
            setErr ({ ...err,
                nameText:''
            })
        };

        if (number.length<=5) {
            setErr ({ ...err,
                numberText:'Please enter a valid number'
            })
        } else {
            setErr ({ ...err,
                numberText:''
            })
        };
        
        if (email.length===0) {
            setErr ({ ...err,
                emailText:'Please enter email'
            })
        } else {
            setErr ({ ...err,
                emailText:''
            })
        };

        if(err.emailText.length===0 && err.nameText.length===0 && err.numberText.length===0) {
            alert('Your request is succesfully submited. We will reach you soon')
            setName('');
            setNumber('');
            setEmail('');
        };
    }
    return (
        <div id='Contact' className='divison paper'>
            <Grid container justify='center'>
                <Grid item xs={11}>
                    <Grid container justify='space-evenly'>
                        <Grid item xs={12} md={6}>
                            <h2 style={{color:'#ffffff'}}>Submit your enquiry and we will contact you</h2>
                            <Grid container justify='space-between' spacing={2} alignItems='center'>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        error ={err.nameText.length === 0 ? false : true }
                                        helperText= {err.nameText}
                                        id="outlined-required"
                                        placeholder="Name"
                                        className="textfield"
                                        margin="normal"
                                        variant="outlined"
                                        value={name}
                                        onChange={handleNameChange}
                                        onBlur={validateName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{marginTop: '9px'}}>
                                    <MuiPhoneNumber
                                        required
                                        error ={err.numberText.length === 0 ? false : true }
                                        helperText= {err.numberText}
                                        defaultCountry={'in'}
                                        variant='outlined'
                                        className='textfield'
                                        placeholder='Phone Number'
                                        value={number}
                                        onChange={handleNumberChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        error ={err.emailText.length === 0 ? false : true }
                                        helperText={err.emailText}
                                        id="outlined-required"
                                        placeholder="Email"
                                        name= "email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        onBlur={validateEmail}
                                        className="textfield"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Message"
                                        margin="normal"
                                        variant="outlined"
                                        type= 'text'
                                        className='textfield'
                                        multiline
                                        rows="4"
                                        value={message}
                                        onChange={handleMessageChange}
                                    />
                                    <Button variant='contained' onClick={onSubmitHandle} style={{marginTop:'30px', backgroundColor: 'white', color: '#0099e7', width:'30%', height: 60, marginRight:5, fontSize:'1.2em', fontWeight: 'bold'}}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <h2 style={{color: 'white'}}>Feel free to contact</h2>
                            <h3 style={{color: 'white'}}>Torit Inc., USA</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>3422 Belridge Drive</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>SE.Smyrna, GA 30080</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>+14439179870</p>
                            <h3 style={{color: 'white'}}>Corporate Address:</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>68 14th Cross 1st Block</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>RT Nagar, Bangalore 560032</p>
                            {/* <h3 style={{color: 'white'}}>Registered Address:</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>C/27 East Rajapur</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>Santoshpur, Kolkata 700075</p> */}
                            <h3 style={{color: 'white'}}>Contact:</h3>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>+918794054793</p>
                            <p style={{color: 'white', fontSize: '1.2em', lineHeight: '0.9em'}}>info@torit.in</p>
                        </Grid>
                    </Grid>
                </Grid>
                <p style={{textAlign: 'center', color: '#ffffff', marginTop: 50}}>Copyright @ 2019, Torit Innovation Pvt. Ltd.</p>
            </Grid>
        </div>
    )
}

export default Bottom
