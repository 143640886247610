import React from 'react'
import { Grid, Paper, Avatar, Hidden, Icon } from '@material-ui/core'
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

const Team = () => {

    const params = {
        slidesPerView: 1,
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
    }

    const items_t = [
        {
            image: 'https://ik.imagekit.io/torit/Ghosh_lLGN8amrC.jpeg',
            name: 'Shirsha Ghosh',
            designation: 'IoT & Mobility',
            social:'https://www.linkedin.com/in/shirsha'
        },
        {
            image: 'https://ik.imagekit.io/torit/tirtha_sZ6EZsUkS.jpeg',
            name: 'Tirtha Mandal',
            designation: 'Data Science & Analytics',
            social:'https://www.linkedin.com/in/tirtha-mandal-69a795a7/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Varun_h7r2mhHMHk.jpeg',
            name: 'Varun MS',
            designation: 'Python & Data Science',
            social:'https://www.linkedin.com/in/tirtha-mandal-69a795a7/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Avinash_xS_VDcPMx.jpeg',
            name: 'Avinash Maurya',
            designation: 'Fullstack Development',
            social:'https://www.linkedin.com/in/avinashnitap/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Neel_kfJeTO6Ox.jpeg',
            name: 'Suman Debnath',
            designation: 'Fullstack Development',
            social:'https://www.linkedin.com/in/sumandn/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Arpita_D4g0UvjW3.jpeg',
            name: 'Arpita Chakraborty',
            designation: 'Digital Marketing',
            social:'https://www.linkedin.com/in/arpita-chakraborty-337094170/'
        }
    ]

    const items_a = [
        {
            image: 'https://ik.imagekit.io/torit/Vidyut_-9iNVHvVZ.jpeg',
            name: 'Dr. Bidyut Bhattacharya',
            designation: 'Professor, NIT Agartala',
            social:'https://www.linkedin.com/in/bidyut1/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Jayakrishnan_08P_gDTci.jpeg',
            name: 'J. Rajagopalan',
            designation: 'AVP, Ramco System',
            social:'https://www.linkedin.com/in/jayakrishnanrg-jack/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Arun_JOleSLHgK.jpeg',
            name: 'Arun Tanksali',
            designation: 'CTO, Nearex',
            social: 'https://www.linkedin.com/in/arun-tanksali/'
        },
        {
            image: 'https://ik.imagekit.io/torit/Vikas_X66FOVDIm.jpg',
            name: 'Vikas Panditrao',
            designation: 'Co-Founder, FIAKS',
            social: 'https://www.linkedin.com/in/vikaspanditrao/'
        },
        // {
        //     image: 'https://ik.imagekit.io/torit/rakesha_ohaQ4vv2h.jpeg',
        //     name: 'Rakesh Aryasomayajula',
        //     designation: 'Swipay Corp.',
        //     social: 'https://www.linkedin.com/in/rakesharyasomayajula/'
        // },
        {
            image: 'https://ik.imagekit.io/torit/Prabhat_jhsL24_vJ.jpeg',
            name: 'Prabhat Chand Pandey',
            designation: 'Senior Engineer, Intel',
            social: 'https://www.linkedin.com/in/prabhat-pandey-55028582/'
        }
    ]

    const member = []
    const adviser= []

    let justify = 'center'

    if(window.innerWidth<=768) {
        justify = 'center';
        for(const [index, item] of items_t.entries()) {
            member.push(
                <div key={index}>
                    <Grid container justify='center'>
                        <Grid item xs={11} md={10}>
                            <Grid container justify='center' alignContent='center' alignItems='center' style={{paddingBottom: 50, flexDirection: 'column'}}>
                                <Avatar alt='blog' src={item.image} style={{width: 120, height: 120}}></Avatar>
                                <h2>{item.name}</h2>
                                <p style={{marginTop: -15}} className='blog-text'>{item.designation}</p>
                                <a href={item.social} target="_blank" rel='noopener noreferrer'><Icon><LinkedInIcon /></Icon></a>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )
        }
        for(const [index, item] of items_a.entries()) {
            adviser.push(
                <div key={index}>
                    <Grid container justify='center'>
                        <Grid item xs={11} md={10}>
                            <Grid container justify='center' alignContent='center' alignItems='center' style={{paddingBottom: 50, flexDirection: 'column'}}>
                                <Avatar alt='blog' src={item.image} style={{width: 120, height: 120}}></Avatar>
                                <h2>{item.name}</h2>
                                <p style={{marginTop: -15}} className='blog-text'>{item.designation}</p>
                                <a href={item.social} target="_blank" rel='noopener noreferrer'><Icon><LinkedInIcon /></Icon></a>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    } else {
        justify = 'space-evenly'
        for(const [index, item] of items_t.entries()) {
            member.push(
                <Grid key={index} item xs={11} md={3}>
                    <Grid container justify='center'alignContent='center' alignItems='center' style={{paddingBottom: 50, flexDirection: 'column'}}>
                        <Avatar alt='blog' src={item.image} style={{width: 150, height: 150}}></Avatar>
                        <h2>{item.name}</h2>
                        <p style={{marginTop: -15}} className='blog-text'>{item.designation}</p>
                        <a href={item.social} target="_blank" rel='noopener noreferrer'><Icon><LinkedInIcon /></Icon></a>
                    </Grid>
                </Grid>
            )
        }

        for(const [index, item] of items_a.entries()) {
            adviser.push(
                <Grid key={index} item xs={11} md={3}>
                    <Grid container justify='center'alignContent='center' alignItems='center' style={{paddingBottom: 50, flexDirection: 'column'}}>
                        <Avatar alt='blog' src={item.image} style={{width: 150, height: 150}}></Avatar>
                        <h2>{item.name}</h2>
                        <p style={{marginTop: -15}} className='blog-text'>{item.designation}</p>
                        <a href={item.social} target="_blank" rel='noopener noreferrer'><Icon><LinkedInIcon /></Icon></a>
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <div id='Team' className='divison'>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={11}>
                    <Paper className='paper' elevation={2}>
                        <h1 className='heading'>Team</h1>
                        <Grid container justify='center'>
                            <Grid item xs={12} md={10}>
                                <Grid container justify={justify} alignItems='center'>
                                    <Hidden mdUp>
                                        <Swiper {...params}>
                                            {member}
                                        </Swiper>
                                    </Hidden>
                                    <Hidden smDown>
                                        {member}
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>

                        <h1 className='heading'>Advisory Board</h1>
                        <Grid container justify='center'>
                            <Grid item xs={12} md={10}>
                                <Grid container justify={justify} alignItems='center'>
                                    <Hidden mdUp>
                                        <Swiper {...params}>
                                            {adviser}
                                        </Swiper>
                                    </Hidden>
                                    <Hidden smDown>
                                        {adviser}
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Team