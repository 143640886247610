import React from 'react'
import { Grid, Paper } from '@material-ui/core'

const Philosophy = () => {
    return (
        <div className='divison'>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={11}>
                    <Paper className='paper' elevation={3}>
                        <Grid container justify='space-evenly' alignItems="center" alignContent='center'>
                            <Grid item md={5} xs={12}>
                                <img className='philosophy-img' src="https://ik.imagekit.io/torit/Component_30_____1_NFNXl__a1.svg" alt='philosophy'></img>
                            </Grid>
                            <Grid item xs={11} md={5}>
                                <h1 className='heading' style={{textAlign: 'left'}}>Our Philosophy</h1>
                                <p className='philosophy-text'>Selecting the true digital transformation starts with proper set of questions. What do your consumers' need? In what measure your competitors are delivering? What are your opportunities in the market? Business is a Game of keeping Upgrade Or Get Left Behind: It's that simple. We assist you in your way of keeping upgraded to notice the answer for a winning digital strategy.</p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Philosophy
