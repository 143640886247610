import React from 'react'
import { Grid, Paper, Avatar } from '@material-ui/core'
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css'


const Product = () => {

    const params = {
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
    }

    if(window.innerWidth<=768) {
        params.slidesPerView= 1
    } else {
        params.slidesPerView= 3
    }

    const items = [
        {
            image: 'https://ik.imagekit.io/torit/5026-new-pass_nX3XhBRX3.gif',
            title: 'Beyond Password',
            desc: 'Coming Soon'
        },
        {
            image: 'https://ik.imagekit.io/torit/ezgif.com-gif-maker_36aV9qdk4.gif',
            title: 'Health Kit',
            desc: 'Coming Soon'
        }
    ]

    const product = []

    for(const [index, item] of items.entries()) {
        product.push(
            <Grid key={index} item xs={11} md={5}>
                <Paper className="service-item" elevation={3}>
                    <img className="product-img" src={item.image} alt='product'></img>
                    <h4>{item.title}</h4>
                    <p>{item.desc}</p>
                </Paper>
            </Grid>
        )
    }

    const clients = [
        'https://ik.imagekit.io/torit/Component_3_____1_3x_tNTYZmcgH.png',
        'https://ik.imagekit.io/torit/Mask_Group_3_3x_jQAvDm8Vp.png',
        'https://ik.imagekit.io/torit/Mask_Group_4_3x_GOIe6_HaoW.png',
        'https://ik.imagekit.io/torit/Mask_Group_5_3x_2B6MPrEjbs.png',
        'https://ik.imagekit.io/torit/Mask_Group_6_3x_CjJzASKBiG.png',
        'https://ik.imagekit.io/torit/Component_2_____1_3x_3C91ds-FK.png'
    ]

    const client = []

    for(const [index, item] of clients.entries()) {
        client.push(
            <Grid key={index} item xs={6} sm={3} md={2}>
                <img className='tech-img' src={item} alt='tech'></img>
            </Grid>
        )
    }

    const testimonial = [
        {
            testimonial: 'I have interacted with Shirsha for brief period and I find him  subject matter expert. I would recommend institutions supporting start-ups and working with start-up to give Shirsha an opportunity. He will do good job',
            image: 'https://ik.imagekit.io/torit/Vikas_X66FOVDIm.jpg',
            name: 'Vikas Panditrao',
            post: 'Co-Founder',
            company: 'FIAKS'
        },
        {
            testimonial: 'We value our relationship with TORIT. They’ve proven themselves to be capable and willing partners in numerous development projects. They are timely, efficient and use best practices in their development work for both Data Analytics & intuitive UX. They move our project along quickly and keep us agile enough to be competitive. I would recommend them to anyone.',
            image: 'https://f6s-public.s3.amazonaws.com/profiles/2075961_original.jpg',
            name: 'Shashidhar Kanukolanu',
            post: 'CEO',
            company: 'Swipay Corp., USA'
        },
        {
            testimonial: 'We are happy with your work done with regards to our website www.easy2lend.com and we are pleased to recommend your work.',
            image: 'https://res-3.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_256,w_256,f_auto,g_faces,z_0.7,q_auto:eco/vg0gef0f2jgu5mzrqamt',
            name: 'Riaz Maniyar',
            post: 'CEO',
            company: 'Easy2lend Creditlearn Technologies India Pvt Ltd'
        }
    ]

    const slide = []

    for(const [index, item] of testimonial.entries()) {
        slide.push(
            <div key={index}>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={11} md={9}>
                        <Grid container justify='center' alignItems='center' alignContent='center' style={{textAlign: 'center', flexDirection:'column', paddingBottom: 30}}>
                            <div className='triangle'>
                                <p>{item.testimonial}</p>
                            </div>
                            <Avatar src={item.image} alt={item.name} style={{margin: '15px auto'}}></Avatar>
                            <h4>{item.name}</h4>
                            <h4 style={{marginTop: '-20px'}}>{item.post}, {item.company}</h4>
                        </Grid>
                    </Grid> 
                </Grid>
            </div>
        )
    }


    return (
        <div id='Products' className='divison'>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={11}>
                    <Paper className='paper' elevation={2}>


                        <h1 className='heading'>Our Products</h1>
                        <Grid container justify='center' spacing={2}>
                            {product}
                        </Grid>


                        <h1 className='heading'>Client and Partners</h1>
                        <Grid container justify='center'>
                            <Grid item xs={11} md={10}>
                                <Grid container justify='center' alignItems='center' alignContent='center'>
                                    {client}
                                </Grid>
                            </Grid>
                        </Grid>


                        <h1 className='heading'>Testimonial</h1>
                        <Grid container justify='center' alignItems='center'>
                            <Grid item xs={12} md={11}>
                                <Grid container justify='center' alignItems='stretch'>
                                    <Swiper {...params}>
                                        {slide}
                                    </Swiper>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Product
