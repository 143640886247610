import React from 'react'
import Navbar from '../../Shared/Navbar'
import Hero from './Hero'
import Service from './Service'
import Philosophy from './Philosophy'
import Product from './Product'
import Incubated from './Incubated'
import Blog from './Blog'
import Team from './Team'
import Bottom from './Bottom'
import { CssBaseline } from '@material-ui/core'

const Home = () => {
    return (
        <div className='main'>
            <CssBaseline />
            <Navbar />
            <Hero />
            <Service />
            <Philosophy />
            <Product />
            <Incubated />
            <Team />
            <Blog />
            <Bottom />
        </div>
    )
}

export default Home;
