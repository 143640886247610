import React from 'react'
import { Grid, Paper } from '@material-ui/core'

const Incubated = () => {
    return (
        <div className='divison'>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={11}>
                    <Paper style={{padding: '50px 10px'}} elevation={3}>
                        <Grid container justify='center' alignItems='center' alignContent='center'>
                            <h1  style={{textAlign: 'center', fontSize: 40, color: '#222222', marginRight: 5}} >Torit is incubated by </h1> <a href='https://www.fiaks.com' target='_blank' rel="noopener noreferrer"><img src='https://www.fiaks.com/wp-content/uploads/2018/07/fiaks_logo_small-e1530628351478.png' alt='Fiask'></img></a>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Incubated
