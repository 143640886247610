import React from 'react';
import { Grid, Button, Modal } from '@material-ui/core';
import ModalForm from '../../../Shared/ModalForm';

const Hero= () => {

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{marginBottom: '20px'}}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={11} md={4}>
                    <div className="hero-block">
                        <div className="hero-head">
                            Increase Conversion
                            Rate by upto <span>10X</span> using<br />
                            Progressive Web App
                        </div>
                        <h3>We can help you do that.</h3>
                        <p className="pr3">We help you save your development costs by 50% and convert visitors into customer through powerful and clear progressive app.</p>
                        <Grid container>
                            <Button style={{textTransform: 'inherit', marginRight: '10px'}} variant="contained" size="large" color="primary" onClick={handleOpen}>Book a free Call Now</Button>
                            <Button style={{textTransform: 'inherit'}} size="large">Or learn how to do it</Button>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item xs={12} md={4}>
                    <img src='https://ik.imagekit.io/torit/1st_CJFrIxLZu.png' alt="Progressive Web App" className="responsive-image hero-block" />
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="Modal-form-title"
                aria-describedby="Modal-form-description"
                open={open}
                onClose={handleClose}
                style={{overflowY:'scroll'}}
            >
                <div className='modal-form'>
                    <ModalForm />
                </div>
            </Modal>
        </div>
    )
}

export default Hero;
