import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './Home'
import Data from './Blog/data'
import ScrollTop from '../Assets/Global/ScrollTop'
import PWA from './pwa/App'

class App extends Component {

  render() {
    return (
      <div>
        <BrowserRouter>
          <ScrollTop />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/home' component={Home} />
            <Route exact path='/blog/data' component={Data} />
            <Route exact path='/pwa' component={PWA} />
          </Switch>
        </BrowserRouter>        
      </div>
    )
  }
}

export default App;
