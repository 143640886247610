import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Service = () => {

    const items = [
        {
            image: 'https://ik.imagekit.io/torit/Component_14_____1_buF_PrxSL.svg',
            title: 'Progressive Web App',
            desc: 'Success of a product starts from its very begining. Right from the thinking process.',
            linked: 'pwa'
        },
        {
            image: 'https://ik.imagekit.io/torit/Component_15_____1_XTdApswqY.svg',
            title: 'Website & Mobile Apps',
            desc: 'Product developmenet is not just build and publish. We tend to create your business better.',
            linked: ''
        },
        {
            image: 'https://ik.imagekit.io/torit/data-science_rZbNZ_3fcV.svg',
            title: 'Data Science & Machine',
            desc: 'IOT includes ability to access information from anywhere at any time on any device.',
            linked: ''
        },
        {
            image: 'https://ik.imagekit.io/torit/internet-of-things_seaPyG6vVt.svg',
            title: 'Internet of Things',
            desc: 'For your digital product listening to the customer behaviour has huge importance.',
            linked: ''
        }    
    ]

    const service = []

    for(const [index, item] of items.entries()) {
        service.push (
            <Grid key={index} item xs={11} md={5}>
                <Link to={item.linked}>
                    <Paper className="service-item" elevation={3}>
                        <img className="service-img" src={item.image} alt='service'></img>
                        <h4>{item.title}</h4>
                        <p>{item.desc}</p>
                    </Paper>
                </Link>
            </Grid>
    )}

    const stack = [
        'https://ik.imagekit.io/torit/Component_16_____1_3x_hlOPvJgFl.png',
        'https://ik.imagekit.io/torit/Component_17_____1_3x_e1RnBw8Jvd.png',
        'https://ik.imagekit.io/torit/Component_18_____1_3x_Tz2twgo3Zh.png',
        'https://ik.imagekit.io/torit/Component_19_____1_3x_oUlwPUQaf.png',
        'https://ik.imagekit.io/torit/Component_20_____1_3x_x34xFImOc.png',
        'https://ik.imagekit.io/torit/Component_21_____1_3x_Q_WaaAcqa.png',
        'https://ik.imagekit.io/torit/Component_22_____1_3x_MJk8P-dUyz.png',
        'https://ik.imagekit.io/torit/Component_23_____1_3x_LwrLAgyks.png',
        'https://ik.imagekit.io/torit/Component_24_____1_3x_9wLs3GEFk.png',
        'https://ik.imagekit.io/torit/Component_25_____1_3x_HMxdA5TEw.png',
        'https://ik.imagekit.io/torit/Component_26_____1_3x_l_rFrTyomz.png',
        'https://ik.imagekit.io/torit/Component_27_____1_3x_i1Q59qAgr.png',
        'https://ik.imagekit.io/torit/Component_28_____1_3x_16CRnTnJU.png',
        'https://ik.imagekit.io/torit/Component_29_____1_3x_31dK13AZ0.png'
    ]

    const tech = []

    for(const[index, item] of stack.entries()) {
        tech.push(
            <Grid key={index} item xs={6} sm={3} md={1}>
                <img className='tech-img' src={item} alt='tech'></img>
            </Grid>
        )
    }

    return (
        <div id='Services' className='divison'>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={11}>
                    <Paper className='paper' elevation={2}>
                        <h1 className='heading'>Our Services</h1>
                        <Grid container justify='center' spacing={2}>
                            {service}
                        </Grid>
                        <h1 className='heading'>Tech Stack</h1>
                        <Grid container justify='center'>
                            <Grid item xs={11} md={10}>
                                <Grid container justify='center' alignItems='center' alignContent='center'>
                                    {tech}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Service
