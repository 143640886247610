import React from 'react';
import { Grid } from '@material-ui/core';

const Benefits= () => {

    const benefit = [
        {
            image: 'https://ik.imagekit.io/torit/surface1_fL-sWyfbF.svg',
            title: 'Increased Conversion Rate',
            desc: 'It enables companies expand their engagement with user and increse the user retention rate'
        },
        {
            image: 'https://ik.imagekit.io/torit/003-laugh_QFs9cnUhJ.svg',
            title: 'User-Friendly',
            desc: 'They provide a great user experience as they are fast, secure and reliable.'
        },
        {
            image: 'https://ik.imagekit.io/torit/wifi-signal-tower_241bQjVGLg.svg',
            title: 'Work inPoor Quality Internet',
            desc: 'PWA works efficiently with low internet speed with less data consumption than Native apps.'
        },
        {
            image: 'https://ik.imagekit.io/torit/Component_8_____1_GY3lPddy3.svg',
            title: 'Loads Lighting Fast',
            desc: 'Load instantly and never show the dinosaur, even in uncertain network conditions'
        },
        {
            image: 'https://ik.imagekit.io/torit/Component_9_____1_83UcnGTe3.svg',
            title: 'No App Update Hassles',
            desc: "Since it requires no download, PWA uses a very small portion of phone's memory"
        },
        {
            image: 'https://ik.imagekit.io/torit/008-development_kW7V9Fq2Iv.svg',
            title: 'Progressive',
            desc: 'PWAs enables app to run on any device and supporting all browsers.'
        },
    ]

    const box = []

    for(const [index, item] of benefit.entries()) {
        box.push(
            <Grid key={index} className="p3" item xs={12} md={4}>
                <Grid container justify="center" alignItems="center" style={{flexDirection: 'column'}}>
                    <img style={{height: '100px', margin:'auto', padding: 'auto'}} src={item.image} alt='benefits'></img>
                    <br />
                    <h5 style={{textAlign: 'center'}}>{item.title}</h5>
                    <p style={{textAlign: 'center'}}>{item.desc}</p>
                </Grid>
            </Grid>
        )
    }

    return (
        <div className="px2">
            <Grid container justify="center">
                <Grid item xs={11}>
                    <h1 style={{textAlign: 'center', marginBottom: '40px'}}>Benefits of Progressive Web App</h1>
                </Grid>
                <Grid item xs={11} md={10}>
                    <Grid container justify="center" alignItems="stretch">
                        {box}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Benefits;