import React from 'react';
import { Grid } from '@material-ui/core';


const Header = () => {
    return (
        <div>
            <Grid container spacing={0} justify="center">
                <Grid item xs={12} lg={10}>
                    <img src='https://ik.imagekit.io/torit/logo_8e1LAuKcD.png' alt="logo" style={{height: '80px', width: 'auto'}} />
                </Grid>
            </Grid>
        </div>
    )
}

export default Header;
