import React from 'react'
import {Grid, Paper} from '@material-ui/core'

const Choose = () => {

    const box = {
        maxWidth: '80%',
        margin:'15% 10%',
        padding: '20px',
        borderRadius : '10px',
        minHeight: '250px',
        textAlign:'center'

    }
    const image = {
        height: '80px',
        borderRadius: '10px',
        margin: 'auto'
    }

    return (
        <div className="px2">
            <Grid container justify='center'>
                <Grid item xs={11} md={12}>
                    <h1 style={{textAlign:'center'}}>Why Should you Choose Us</h1>
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems='stretch'>
                <Grid item xs={12} md={4}>
                    <Paper style={box} elevation={3}>
                        <img src='https://ik.imagekit.io/torit/Component_10_____1_uMGuMySYrR.svg' alt='Choose' style={image}></img>
                        <h4>One Time Guarantee</h4>
                        <p>If we delay, based on the agreement client can deduct payment based on the agreed terms</p>
                    </Paper>
                    <Paper style={box} elevation={3}>
                        <img src='https://ik.imagekit.io/torit/Group_222_42cane8rz.svg' alt='Choose' style={image}></img>
                        <h4>Experienced Team</h4>
                        <p>All our developers have at least 3 years of experience. We also regularly engage them to adopt new technologies</p>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper style={box} elevation={3}>
                        <img src='https://ik.imagekit.io/torit/Component_11_____1_vvkQs8MqH.svg' alt='Choose' style={image}></img>
                        <h4>Service Guarantee</h4>
                        <p>24/7 Service and guaranteed support for upto 6 months aer Go live for all projects</p>
                    </Paper>
                    <Paper style={box} elevation={3}>
                        <img src='https://ik.imagekit.io/torit/Group_222_42cane8rz.svg' alt='Choose' style={image}></img>
                        <h4>Experienced Team</h4>
                        <p>All our developers have at least 3 years of experience. We also regularly engage them to adopt new technologies</p>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Choose
