import React, {useState} from 'react';
import {Grid, TextField, Hidden, Button, MenuItem } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number'


const ModalForm = ()=> {

    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')    
    const [message, setMessage] = useState('')
    const [service, setService] = useState('')
    const [budget, setBudget] = useState('')
    const [err, setErr] = useState({emailText: '', nameText:'', numberText:'', budgetText: '', serviceText:'' })

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const handleNameChange = event => {
        setName(event.target.value)
    }

    const handleNumberChange = event => {
        setNumber(event)
    }

    const handleServiceChange = event => {
        setService(event.target.value)
    }

    const handleBudgetChange = event => {
        setBudget(event.target.value)
    }

    const handleMessageChange = event => {
        setMessage(event.target.value)
    }

    const validateEmail = () => {

        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            setErr({ ...err,
                emailText: ''
            })
        } else {
            setErr({ ...err,
                emailText: 'Please enter a valid email'
            })
        }
    }

    const validateName = () => {
        let re = /^[A-Za-z\s]+$/
        if(re.test(name)) {
            setErr ({ ...err,
                nameText: ''
            })
        } else {
            setErr ({ ...err,
                nameText: 'Please enter a valid name'
            })
        }
    }

    const onSubmitHandle = () => {

        if (name.length===0) {
            setErr ({ ...err,
                nameText:'Please enter name'
            })
        } else {
            setErr ({ ...err,
                nameText:''
            })
        };

        if (service.length===0) {
            setErr ({ ...err,
                serviceText:'Please Select one option'
            })
        } else {
            setErr ({ ...err,
                serviceText:''
            })
        };

        if (budget.length===0) {
            setErr ({ ...err,
                budgetText:'Please Select one option'
            })
        } else {
            setErr ({ ...err,
                budgetText:''
            })
        };

        if (number.length<=5) {
            setErr ({ ...err,
                numberText:'Please enter a valid number'
            })
        } else {
            setErr ({ ...err,
                numberText:''
            })
        };
        
        if (email.length===0) {
            setErr ({ ...err,
                emailText:'Please enter email'
            })
        } else {
            setErr ({ ...err,
                emailText:''
            })
        };

        if(err.emailText.length===0 && err.nameText.length===0 && err.serviceText.length===0 && err.budgetText.length===0 && err.numberText.length===0) {
            alert('Your request is succesfully submited. We will reach you soon')
            setName('');
            setService('');
            setBudget('');
            setNumber('');
            setEmail('');
            setMessage('');
        };
    }

    return (
        <div>
            <Grid container justify="space-between" alignItems='center'>
                <Grid item xs={12}>
                    <h1 style={{textAlign:'center'}}>Let us know more</h1>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container justify='center' spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                error ={err.nameText.length === 0 ? false : true }
                                helperText= {err.nameText}
                                id="outlined-required"
                                label="Name"
                                className="textfield"
                                margin="normal"
                                variant="outlined"
                                value={name}
                                onChange={handleNameChange}
                                onBlur={validateName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                error ={err.serviceText.length === 0 ? false : true }
                                helperText= {err.serviceText}
                                id="outlined-select-currency"
                                select
                                label="Service"
                                className='textfield'
                                value={service}
                                onChange={handleServiceChange}
                                margin="normal"
                                variant="outlined"
                                >
                                {['Progressive Web App', 'Data Science & Analytics', 'Internet of Things'].map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                error ={err.budgetText.length === 0 ? false : true }
                                helperText= {err.budgetText}
                                id="outlined-select-currency"
                                select
                                label="Budget"
                                className='textfield'
                                value={budget}
                                onChange={handleBudgetChange}
                                margin="normal"
                                variant="outlined"
                                >
                                {['<₹2Lakh/200K', '₹2-5Lakh/200K-500K', '₹5-10Lakh/500K-1M', '>₹10Lakh/1M'].map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPhoneNumber
                                required
                                error ={err.numberText.length === 0 ? false : true }
                                helperText= {err.numberText}
                                defaultCountry={'in'}
                                variant='outlined'
                                className='textfield'
                                label='Phone Number'
                                value={number}
                                onChange={handleNumberChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                error ={err.emailText.length === 0 ? false : true }
                                helperText={err.emailText}
                                id="outlined-required"
                                label="Email"
                                name= "email"
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={validateEmail}
                                className="textfield"
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="outlined-required"
                                placeholder="Message"
                                margin="normal"
                                variant="outlined"
                                type= 'text'
                                className='textfield'
                                multiline
                                rows="4"
                                value={message}
                                onChange={handleMessageChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                                <Button onClick={()=>onSubmitHandle()} style={{textTransform: 'inherit'}} variant="contained" size="large" color="primary" >Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Hidden mdDown>
                        <img className="responsive-img" src='https://ik.imagekit.io/torit/contact_T0KP64_Vdx.jpg' alt="demo" />
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    )
}

export default ModalForm;
