import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css'
import Items from '../../../Assets/blog.json'
import { Link } from 'react-router-dom'

const Blog = () => {

    const params = {
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
    }

    if(window.innerWidth<=768) {
        params.slidesPerView= 1
    } else {
        params.slidesPerView= 3
    }

    const blog = []

    for(const [index, item] of Items.entries()) {
        blog.push(
            <div key={index}>
                <Grid container justify='center'>
                    <Grid item xs={11} md={10}>
                        <Grid container style={{paddingBottom: 20}}>
                            <img className='responsive-img' alt='blog' src={item.image}></img>
                            <h2>{item.title}</h2>
                            <p className='blog-text'>{item.desc.replace(/^(.{200}[^\s]*).*/, "$1")}</p>
                            <Link to={`blog/${item.id}`.toLowerCase()}><h4 className='blue' style={{textAlign:'left !important'}}>Read more</h4></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <div id='News' className='divison'>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={11}>
                    <Paper className='paper' elevation={2}>
                        <h1 className='heading'>News & Blog</h1>
                        <Grid container justify='center'>
                            <Grid item xs={12} md={11}>
                                <Grid container justify='center' alignItems='center'>
                                    <Swiper {...params}>
                                    {blog}
                                    </Swiper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Blog
