import React, {useState} from 'react'
import { 
  AppBar,
  Toolbar,
  Button,
  useScrollTrigger,
  CssBaseline,
  Hidden,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
//import { Link } from 'react-router-dom'

const ElevationScroll = (props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
}

const Navbar = (props) => {

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const navlist = ['Home', 'Services', 'Products', 'Team', 'News', 'Contact']
    
      const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [side]: open });
      };
    
      const sideList = side => (
        <div
          className='drawer'
          role="presentation"
          onClick={toggleDrawer(side, false)}
          onKeyDown={toggleDrawer(side, false)}
        >
          <List>
            {navlist.map((text, index) => (
              <a key={index} href={`#${text}`}>
                <ListItem button key={index}>
                  <ListItemText primary={text} />
                </ListItem>
              </a>
            ))}
          </List>
        </div>
      );

    return (
        <div>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar>
                    <Toolbar className='bg-blue'>
                        <Grid container justify="center">
                            <Grid item xs={12} md={11}>
                                <Grid container justify='space-between' alignItems='center' alignContent='center'>
                                    <Grid item xs={8} md={3}>
                                        <img className="nav-img" src='https://ik.imagekit.io/torit/logo_8e1LAuKcD.png' alt='Logo'></img>
                                    </Grid>
                                    <Grid item xs={2} md={6}>
                                        <Hidden smDown>
                                            <Grid container justify='flex-end' spacing={3}>
                                                {navlist.map((text, index) => (
                                                  <a key={index} href={`#${text}`}><Button key={index} className="nav-item">{text}</Button></a>
                                                ))}
                                            </Grid>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Grid container justify='flex-end'>
                                                <MenuIcon onClick={toggleDrawer('left', true)} />
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                {sideList('left')}
            </Drawer>
        </div>
    )
}

export default Navbar