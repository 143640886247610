import React from 'react'
import { Grid, Hidden, CssBaseline } from '@material-ui/core'
import { Link } from 'react-router-dom'

function Data() {
    return (
        <div id='blog' className='blogpage' >
            <CssBaseline />
            <Grid container justify='center'>
                <Grid item xs={12}>
                    <Grid container justify='space-between' alignItems='center' alignContent='center'>
                        <Grid item xs={8} md={3}>
                            <Link to='/'><img className="responsive-img" src='https://torit.io/static/media/logo.91479981.png' alt='Logo'></img></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                <Grid item xs={11} md={8}>
                    <img className="responsive-img" src='https://ik.imagekit.io/torit/Data-Science-101_f7woGPjw9.png' alt='Data Science'></img>
                    <p className='credit'>Credit: dare2compete.com</p>
                    <div className='mt30'>
                        <h1>Data Science, Why you should be serious about it</h1>
                        <p>As the world has turned into the era of big data, the need of storage has also been increasing linearly. It was one of the main challenge for the enterprise industries until 2010. The primary focus was on building framework and solutions to store data. As now the Hadoop and other frameworks have successfully solved the problem of storage, the focus has shifted to the processing of this data. Data Science is the secret element here. All the ideas which you see in Hollywood science-fiction movies can actually turn into reality by Data Science. Data Science is the future of Artificial Intelligence, gaining as much knowledge as possible about Data Science is important and this way can we can add value to our business.</p>
                        <p>Without the expertise of professionals who resourcefully turn cutting-edge technology into intuitional action, Big Data is nothing. Today, more and more organizations are opening up to big data and unlocking its power, which helps on increasing the value of a data scientist who knows how to tease actionable intuition out of gigabytes of data.</p>

                        <p>Saying about their expertise field, let's understand how Data science is a game changer:</p>
                        <ul>
                            <li>It’s become a universal truth that modern businesses are covered with data. Typically, the data that we had was mostly structured and small in size, which could be analyzed easily with the help of simple BI tools. Unlike data in the custom systems which was mostly structured, today most of the data is unstructured or semi-structured.</li>
                            <li>Such data from different sources like financial logs, text files, multimedia forms, sensors, and instruments could be processed by using simple BI tools. This is why we need more complex and advanced analytical approach and algorithms for processing, analyzing and drawing meaningful insights out of today's informal data. </li>
                        </ul>
                        <p>These are not the only reason why Data Science has become so popular. With the ever increasing popularity of data science and new technologies and sophisticated developments, the applications of data science are increasing in leaps and bounds over time. Let’s dig a little deeper and know the interlocking story of how Data Science has been variously used: </p>
                        <ul>
                            <li>Data science, for a significant change could understand the precise requirements of customers from the existing data like the customer’s past browsing history, purchase history, age and income. No doubt the company had all these data earlier too, but now with increasing amount and variety of data, company can train models more effectively and recommend the product to customers with more precision. Wouldn’t it be amazing as it will establish a huge value to individual organization? </li>
                            <li>Equipped with shield of hope, let's move further to another scenario in order to understand the importance of Data Science in decision making. Like, how about if your car had the intelligence to drive you home? The automatic self-driving cars collect live data from sensors, radars, cameras and lasers to have a map of its surroundings. Based on this organized data, it takes decisions like when to speed up, when to speed down, when to overtake, where to take a turn – making use of advanced machine learning algorithms. </li>
                            <li>Similarly, taking weather forecasting as another example. We know that data from ships, aircrafts, radars, satellites can be collected and analyzed to build models. These models will not only help in forecasting the weather but also help in predicting the occurrence of any natural calamities. It will help you to take appropriate measures beforehand and save many lives based on predictions made in Data Science. </li>
                            <li>Talking about Regulatory schemes and with the plenty of information being created each second, and the pace being quickened by impetuses like IoT, the issue of data security will turn out to be increasingly significant. It may be sensibly expected to have more information about administrative plans in 2019. Administrative plans like, for instance GDPR (European General Data Protection Regulation), which was authorized on May, 2018 directed information practice by defining certain limits and points of confinement on accumulation and the executives of individual information. Such administrative exercises will gigantically affect future prescient models and distinctive scientific activities. In addition, the inexorably complex cyberattacks have  commanded the requirement for a less helpless information assurance plot. The prominent informational ruptures uncover our deficiency in this angle. Such a large number of all the more new conventions and methods to verify information are probably going to develop in 2019. </li>
                            <li>The buzz made by AI is probably not going to subside in the coming year. We are in the beginning and starting phase of AI, and the next year will see the further developed use of AI in every one of the fields. Bridling AI will even now remain a test. Increasingly canny applications will be created utilizing AI, Machine Learning and different innovations. Computerized AI (ML) will end up normal and it will change data science with better information on the board. There will likewise be the advancement of explicit equipment for preparing and execution of profound learning. Fuse of AI will upgrade basic leadership and improve the general business experience. Applications and different administrations will progressively depend on AI to improve the general understanding. All the new applications will consolidate some type of AI in their program to improve their working. Thus, the quantity of keen applications will be on the ascent. Insightful things that are more intelligent adaptations of ordinary devices will keep on flooding the market. </li>
                        </ul>
                        <p>In total, data science is a mix of different apparatuses, calculations, and AI standards with the objective to find concealed examples from the crude information. It’s appropriate response lies in the distinction among clarifying and foreseeing. Data Scientist not only exclusively does the exploratory examination to find bits of knowledge from it, yet in addition utilizes different propelled AI calculations to recognize the event of a specific purpose later on. In this phase, a Data Scientist will take a gander at the information from numerous edges, here and there edges not known before.  Along these lines, Data Science is fundamentally used to settle on choices and forecasts utilizing prescient causal investigation, prescriptive examination (prescient in addition to choice science) and AI. By mining the tremendous measure of information that is already accessible, in-house preparing for resumes and applications—and even advanced information driven bent tests and amusements—information science can enable enlistment in order to make organizational work speedier and make progressively precise choices. Data science can enhance any business who can utilize their information well. From measurements and experiences over work processes and procuring new hopes, to help ranking staff settle on better-educated choices, data science is important to any organization in any industry. </p>
                        <p>Keen on a profession in data science company? Torit Innovations  offers a wide scope of courses in data science tutorials that emphasis on everything from R Programming and SAS to Analytics, Hadoop and Spark. You'll be set up to prevail with teacher drove preparation from industry specialists, just helping with active experience of data scientist skills, practice tests on data analytics and providing excellent eLearning content. </p>
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid item md={3}></Grid>
                </Hidden>
                <Grid item xs={11}>
                <Link to='/'>
                    <h3 className='mt30'>Go back</h3>
                </Link>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default Data
