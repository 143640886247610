import React from 'react';
import './App.css';

import Header from './Header';
import Hero from './Hero';
import Client from './Client';
import Content from './Content';
import Benefits from './Benefits';
import Choose from './Choose';
// import Case from './Case';
// import Testimonials from './Testimonials';
import Contact from './Contact';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Client />
      <Content />
      <Benefits />
      <Choose />
      {/* <Case />
      <Testimonials /> */}
      <Contact />
    </div>
  );
}

export default App;
