import React from 'react'
import { Grid } from '@material-ui/core'

const Content= () => {
    return (
        <div className='px2'>
            <Grid container justify='center'>
                <Grid item xs={11} md={8}>
                    <Grid container justify='center'>
                        <Grid className="p2" item xs={12} md={6}>
                            <img className="responsive-image" src="https://ik.imagekit.io/torit/Component_1_____1_vaMOvGP0h.svg" alt=""></img>
                        </Grid>
                        <Grid className="p2" item xs={12} md={6}>
                            <h1>Do your native app sucks at aquiring customers?</h1>
                            <p>Are you constantly tweaking up your application for acuiring new Customers but failing to see the result? There's probably a tonne of pressure on you to get more customers</p>
                            <p>But native designing is hard and takes lot of time and money. But you are giving you all.</p>
                            <h5>And yet, customers don't download your app.</h5>
                        </Grid>
                        <Grid className="p2" item xs={12} md={6}>
                            <h1>Because what you really need is a high performing<br />Progressive Web App</h1>
                            <h5>That gets your customers, consistently</h5>
                            <p>Are you constantly tweaking up your application for acuiring new Customers but failing to see the result? There's probably a tonne of pressure on you to get more customers</p>
                            <p>But native designing is hard and takes lot of time and money. But you are giving you all.</p>
                        </Grid>
                        <Grid className="p2" item xs={12} md={6}>
                            <img className="responsive-image" src="https://ik.imagekit.io/torit/Component_7_____1_jFnNCBMUU.svg" alt=""></img>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Content;
